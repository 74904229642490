<template>
  <div>
    <div
      v-for="language in native_languages"
      :key="language.id"
      class="d-flex flex-row"
    >
      <div class="w-50 text-center">
        {{ getEthnicityName(language.ethnicity) }}
      </div>
      <b-form-checkbox
        class="w-25"
        v-model="language.speak"
        :disabled="true"
        >Habla</b-form-checkbox
      >
      <b-form-checkbox v-model="language.understand" :disabled="true"
        >Entiende</b-form-checkbox
      >
    </div>
    <b-button
      size="sm"
      variant="link"
      @click="$bvModal.show('modal-native-lenguages')"
      >Seleccionar</b-button
    >
    <template v-if="allows_crud">
      <b-modal
        id="modal-native-lenguages"
        title="Seleccionar Idiomas Nativos"
        ok-only
        @ok="slotSelectedNativeLanguages"
      >
        <div
          v-for="native_language in native_languages_to_select"
          :key="native_language.id"
          class="d-flex flex-row"
        >
          <div class="w-50 text-center">
            {{ getEthnicityName(native_language.ethnicity) }}
          </div>
          <b-form-checkbox
            class="w-25"
            v-model="native_language.speak"
            :disabled="!allows_crud"
            >Habla</b-form-checkbox
          >
          <b-form-checkbox
            v-model="native_language.understand"
            :disabled="!allows_crud"
            >Entiende</b-form-checkbox
          >
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "NativeLenguages",
  props: {
    Social: {
      type: Object,
      required: true,
    },
    Ethnicities: {
      type: Array,
      default: function () {
        return [];
      },
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      native_languages: [],
      native_languages_to_select: [],
    };
  },
  methods: {
    fetchNativeLanguages() {
      if (isNaN(this.Social.id)) return;
      this.Social.native_languages.forEach((x)=>{
        this.$restful
          .Get(`/cideu/native-language/${x}/`)
          .then((response) => {
            this.native_languages.push(response);
            this.native_languages_to_select.forEach((a)=>{
              if(a.ethnicity == response.ethnicity){
                a.id = response.id;
                a.speak = response.speak
                a.understand = response.understand
              }
            })
            // response.forEach((nl) => {
            //   const index = this.native_languages_to_select.find(
            //     (x) => x.ethnicity == nl.ethnicity
            //   );
            //   if (index != -1) {
            //     this.native_languages_to_select.splice(index, 1, nl);
            //   }
            // });
          });
      })
    },
    getEthnicityName(ethnicity_id) {
      const ethnicity = this.Ethnicities.find((x) => x.id == ethnicity_id);
      return ethnicity ? ethnicity.name : "-";
    },
    slotSelectedNativeLanguages() {
      this.native_languages = [];
      this.native_languages_to_select.forEach((nl) => {
        if (nl.speak && isNaN(nl.id) || nl.understand && isNaN(nl.id)) {
          // this.$restful.Post(`/cideu/native-language/`,nl).then((response)=>{
            this.native_languages.push(nl);
          // })
        }
        else if(nl.speak && !isNaN(nl.id) || nl.understand && !isNaN(nl.id)){
          // this.$restful.Put(`/cideu/native-language/${nl.id}/`,nl).then((response)=>{
            this.native_languages.push(nl);
          // })
        }
        else if (!nl.speak && !nl.understand && !isNaN(nl.id)) {
          // this.deleteNativeLanguage(nl.id);
        }
      });
      this.$emit("updated", this.native_languages_to_select);
    },
    // deleteNativeLanguage(native_id) {
    //   this.$restful.Delete(`/cideu/native-language/${native_id}/`).then(() => {
    //   });
    // },
  },
  created() {
    this.Ethnicities.forEach((ethnicity) => {
      this.native_languages_to_select.push({
        id: generateUniqueId(),
        ethnicity: ethnicity.id,
        speak: false,
        understand: false,
      });
    });
    this.fetchNativeLanguages();
  },
};
</script>

<style>
</style>